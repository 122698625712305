.App {
  text-align: center;
}

#root,
body,
html {
  height: 100%;
  width: 100%;

  font-family: 'Montserrat', sans-serif;
}

a {
  color: white;
}

a,
a:active,
a:focus {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fl-row {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.fl-column {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.fl-full-center {
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.fl-division {
  flex: 1;
}

.fl-wrap {
  flex-wrap: wrap;
}

.fl-align-center {
  align-items: center;
}

.fl-align-start {
  align-items: flex-start;
}

.fl-align-end {
  align-items: flex-end;
}

.fl-align-baseline {
  align-items: baseline;
}

.fl-justify-start {
  justify-content: flex-start;
}

.fl-justify-end {
  justify-content: flex-end;
}

.fl-justify-baseline {
  justify-content: baseline;
}

.fl-justify-center {
  justify-content: center;
}

.fl-text-center {
  text-align: center;
}

.fl-text-start {
  text-align: start;
}

.fl-text-end {
  text-align: end;
}

.fl-full-width {
  width: 100%;
}

.fl-full-height {
  height: 100%;
}

.fl-text-60,
.fl-text-60 > ::placeholder {
  font-size: 3.125vw !important;
}

.fl-text-50,
.fl-text-50 > ::placeholder {
  font-size: 2.604vw !important;
}

.fl-text-40,
.fl-text-40 > ::placeholder {
  font-size: 2.083vw !important;
}

.fl-text-30,
.fl-text-30 > ::placeholder {
  font-size: 1.563vw !important;
}

.fl-text-27,
.fl-text-27 > ::placeholder {
  font-size: 1.406vw !important;
}

.fl-text-24,
.fl-text-24 > ::placeholder {
  font-size: 1.25vw !important;
}

.fl-text-21,
.fl-text-21 > ::placeholder {
  font-size: 1.094vw !important;
}

.fl-text-18,
.fl-text-18 > ::placeholder {
  font-size: 0.938vw !important;
}

.fl-text-15,
.fl-text-15 > ::placeholder {
  font-size: 0.781vw !important;
}

.fl-text-12,
.fl-text-12 > ::placeholder {
  font-size: 0.625vw !important;
}

.fl-text-10,
.fl-text-10 > ::placeholder {
  font-size: 0.521vw !important;
}

.fl-text-8,
.fl-text-8 > ::placeholder {
  font-size: 0.417vw !important;
}

i {
  font-size: 1.302vw;
}

.i2 {
  font-size: 1vw !important;
}

@media (max-width: 1920px) {
  .fl-text-60,
  .fl-text-60 > ::placeholder {
    font-size: 60px !important;
  }

  .fl-text-50,
  .fl-text-50 > ::placeholder {
    font-size: 50px !important;
  }

  .fl-text-40,
  .fl-text-40 > ::placeholder {
    font-size: 40px !important;
  }

  .fl-text-30,
  .fl-text-30 > ::placeholder {
    font-size: 30px !important;
  }

  .fl-text-27,
  .fl-text-27 > ::placeholder {
    font-size: 27px !important;
  }

  .fl-text-24,
  .fl-text-24 > ::placeholder {
    font-size: 24px !important;
  }

  .fl-text-21,
  .fl-text-21 > ::placeholder {
    font-size: 21px !important;
  }

  .fl-text-18,
  .fl-text-18 > ::placeholder {
    font-size: 18px !important;
  }

  .fl-text-15,
  .fl-text-15 > ::placeholder {
    font-size: 15px !important;
  }

  .fl-text-12,
  .fl-text-12 > ::placeholder {
    font-size: 12px !important;
  }

  .fl-text-10,
  .fl-text-10 > ::placeholder {
    font-size: 10px !important;
  }

  .fl-text-8,
  .fl-text-8 > ::placeholder {
    font-size: 8px !important;
  }

  i {
    font-size: 25px;
  }

  .i2 {
    font-size: 19px !important;
  }
}

@media (max-width: 400px) {
  .fl-text-60,
  .fl-text-60 > ::placeholder {
    font-size: 15vw !important;
  }

  .fl-text-50,
  .fl-text-50 > ::placeholder {
    font-size: 12.5vw !important;
  }

  .fl-text-40,
  .fl-text-40 > ::placeholder {
    font-size: 10vw !important;
  }

  .fl-text-30,
  .fl-text-30 > ::placeholder {
    font-size: 7.5vw !important;
  }

  .fl-text-27,
  .fl-text-27 > ::placeholder {
    font-size: 6.75vw !important;
  }

  .fl-text-24,
  .fl-text-24 > ::placeholder {
    font-size: 6vw !important;
  }

  .fl-text-21,
  .fl-text-21 > ::placeholder {
    font-size: 5.25vw !important;
  }

  .fl-text-18,
  .fl-text-18 > ::placeholder {
    font-size: 4.5vw !important;
  }

  .fl-text-15,
  .fl-text-15 > ::placeholder {
    font-size: 3.75vw !important;
  }

  .fl-text-12,
  .fl-text-12 > ::placeholder {
    font-size: 3vw !important;
  }

  .fl-text-10,
  .fl-text-10 > ::placeholder {
    font-size: 2.5vw !important;
  }

  .fl-text-8,
  .fl-text-8 > ::placeholder {
    font-size: 2vw !important;
  }

  i {
    font-size: 6.25vw;
  }

  .i2 {
    font-size: 4.75vw !important;
  }
}

*:focus {
  outline: none;
}
